import {QRCodeSVG} from 'qrcode.react';
import BookEntity from "../types/BookEntity";
import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {format} from 'date-fns';
import ApiResponse from "../types/ApiResponse";
import NewNote from "./NewNote";
import {CircularProgress, Grid, Link, Sheet} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import Notes from "./Notes";
import {useUserDataContext} from "../hooks/UserDataContext";
import {formatDistanceToNow} from "date-fns/formatDistanceToNow";

const Book: React.FC = () => {
    const [book, setBook] = useState<BookEntity>();
    const {bookId} = useParams();
    const [reload, setReload] = useState(false);
    const {userData, sessionIssue} = useUserDataContext();
    const hasFetched = useRef(false);
    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;


    const formatDate = (dateString: string) => {
        let date = new Date(dateString);
        return format(date, 'MMM d, yyyy');
    }

    useEffect(() => {
        async function fetchAndSetBook() {
            if(hasFetched.current) {
                return;
            }
            hasFetched.current = true;
            let bookResponse = await fetchBook();
            if (bookResponse) {
                setBook(bookResponse);
            }
        }

        fetchAndSetBook();
    }, []);

    const fetchBook = async (): Promise<BookEntity | undefined> => {
        try {
            const response = await fetch(apiUrl + '/books/' + bookId, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userData?.token
                },
            });

            let apiResponse = await response.json() as ApiResponse<BookEntity>;
            if (response.ok) {
                return apiResponse.data;
            }
            if (response.status === 403) {
                sessionIssue();
            } else {
                navigate("/NotFound");
            }
        } catch (error) {
            console.error('There was an error!', error);
        }
        return undefined;
    };

    return (

        <main>
               <Sheet sx={{
                    width: 650,
                    my: 4,
                    py: 3,
                    px: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    borderRadius: 'sm',
                    boxShadow: 'md',
                    mx: 'auto',
                }}
                >
                    <h1>Book</h1>
                   {book && (
                    <Grid container spacing={2}>
                        <Grid xs={4}>
                            <Typography level="body-lg">Title</Typography>
                        </Grid>
                        <Grid xs={8}>
                            <Typography level="body-md">{book?.title}</Typography>
                        </Grid>
                        <Grid xs={4}>
                            <Typography level="body-lg">Author(s)</Typography>
                        </Grid>
                        <Grid xs={8}>
                            <Typography level="body-md">{book?.authors}</Typography>
                        </Grid>
                        <Grid xs={4}>
                            <Typography level="body-lg">Publication Date</Typography>
                        </Grid>
                        <Grid xs={8}>
                            <Typography
                                level="body-md">{book.publishDate ? formatDate(book?.publishDate?.toString()) : "-"}</Typography>
                        </Grid>
                        <Grid xs={4}>
                            <Typography level="body-lg">ISBN</Typography>
                        </Grid>
                        <Grid xs={8}>
                            <Typography level="body-md">{book?.isbn}</Typography>
                        </Grid>
                        <Grid xs={4}>
                            <Typography level="body-lg">Donated At</Typography>
                        </Grid>
                        <Grid xs={8}>
                            <Typography level="body-md" title={formatDate(book?.createdAt.toString())}>{formatDistanceToNow(book?.createdAt, {addSuffix: true})}</Typography>
                        </Grid>
                        <Grid xs={4}>
                            <Typography level="body-lg">QR Code</Typography>
                        </Grid>
                        <Grid xs={8} sx={{display: "flex", flexDirection: "column"}}>
                            <QRCodeSVG value={window.location.toString()}/>
                            <Link href={window.location.toString().replace("/book", "/book/print")}>Print View</Link>
                        </Grid>
                    </Grid>
                   )}
                   {!book && (
                       <CircularProgress/>
                   )}
                </Sheet>
            <Notes bookId={bookId!} reload={reload}/>
            <NewNote toggleReload={()=> {setReload(!reload)}}/>
        </main>
    )
}

export default Book