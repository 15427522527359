import {QRCodeSVG} from 'qrcode.react';
import React, {useState} from "react";
import {Box, Input, Link, Sheet} from "@mui/joy";
import Typography from "@mui/joy/Typography";

const Book: React.FC = () => {
    const [greeting, setGreeting] = useState("Scan this code to let me know how you liked the book")

    return (
        <Sheet sx={{
            width: 650,
            my: 4,
            py: 3,
            px: 2,
            display: 'flex',
            flexDirection: 'column',
            mx: 'auto',
        }}
        >
            <Input type="text" sx={{displayPrint: 'none', mb: 2}} value={greeting} onChange={(e) => {
                setGreeting(e.target.value)
            }}></Input>
            <Box sx={{
                border: 1,
                px: 1,
                py: 0.5,
                textAlign: "center",
                displayPrint: "block",
                width: "300px",
            }}>
                <QRCodeSVG
                    value={window.location.toString().replace("/print", "")}
                    style={{height: '90px', width: '90px'}}/>
                <Typography level="title-sm">{greeting}</Typography>
            </Box>
            <Typography level="body-sm" sx={{displayPrint: 'none'}}>Only the box above will be visible when
                printing</Typography>
            <Link href={window.location.toString().replace("/print", "")} sx={{displayPrint: 'none'}}>Back to
                book</Link>
        </Sheet>
    )
}

export default Book