import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useErrors} from "../hooks/useErrors";
import {Button, CircularProgress, Sheet} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import FormWithErrors from "./FormWithErrors";
import FormFieldWithErrors from "./FormFieldWithErrors";
import {useMessageContext} from "../hooks/MessageContext";
import {useUserDataContext} from "../hooks/UserDataContext";

const NewBook: React.FC = () => {
    const initialState = {isbn: ''};
    const [formData, setFormData] = useState<Record<string,string>>(initialState)
    const navigate = useNavigate();
    const {errors, updateErrors} = useErrors();
    const [error] = useState('')
    const {setMessage} = useMessageContext();
    const {userData, sessionIssue} = useUserDataContext();
    const apiUrl = process.env.REACT_APP_API_URL;
    const [submitting, setSubmitting] = useState(false);

    const handleSubmit = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();

        try {
            setSubmitting(true);
            const response = await fetch(apiUrl + '/book', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userData?.token
                },
                body: JSON.stringify(formData),
            });

            if (response.status === 201) {
                let location = response.headers.get('location');
                console.log(location);
                if (location) {
                    const url = new URL(location);
                    const bookId = url.pathname.split('/').pop();
                    setMessage("Your book was successfully entered!", "success");
                    navigate(`/book/${bookId}`);
                } else {
                }
            } else if (response.status === 403) {
                sessionIssue();
            } else {
                response.json().then((jsonResponse) => {
                    updateErrors(jsonResponse);
                })
            }
        } catch (error) {
            console.error('There was an error!', error);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Sheet
            sx={{
                width: 350,
                mx: 'auto',
                my: 4,
                py: 3,
                px: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                borderRadius: 'sm',
                boxShadow: 'md',
            }}
            variant="outlined">
            <div>
                <Typography level="h4" component="h1">
                    <b>New Book</b>
                </Typography>
                <Typography level="body-sm">Just provide the ISBN number below and all information will be pulled in automatically!</Typography>
            </div>
            <FormWithErrors onSubmit={handleSubmit} formData={formData} error={error} errors={errors} setFormData={setFormData}>
                    <FormFieldWithErrors name="isbn"
                        type="text"
                        required={true}
                        placeholder="0000000000000"
                    />
                <Button sx={{ mt: 1}} type="submit" value="Start Tracking!" disabled={submitting}>
                    <CircularProgress sx={{display: submitting ? "inline-block" : "none"}}/>
                    Start Tracking!
                </Button>
            </FormWithErrors>
        </Sheet>
    )
}

export default NewBook