import React, {useState} from "react";
import {useParams} from "react-router-dom";
import ApiResponse from "../types/ApiResponse";
import {useErrors} from "../hooks/useErrors";
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormHelperText,
    FormLabel,
    Input,
    Sheet,
    Textarea
} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import FormWithErrors from "./FormWithErrors";
import FormFieldWithErrors from "./FormFieldWithErrors";
import NoteResponse from "../types/NoteResponse";
import {useUserDataContext} from "../hooks/UserDataContext";

interface NewNoteProps {
    toggleReload: () => void,
}

const NewNote: React.FC<NewNoteProps> = ({toggleReload}) => {
    const {bookId} = useParams();
    const {userData, sessionIssue} = useUserDataContext();
    const initialFormData = { displayName: userData?.username || '', note: '', addressZip: '', pickedUp: false, droppedOff: false };
    const [formData, setFormData] = useState<Record<string,any>>(initialFormData);
    const {errors, updateErrors} = useErrors();
    const [error, setError] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL;
    const [submitting, setSubmitting] = useState(false);

    const handleSubmit = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();

        try {
            setSubmitting(true);
            let headers = {
                'Content-Type': 'application/json',
                ...(userData ? {'Authorization': 'Bearer ' + userData.token} : {})
            };

            const response = await fetch(apiUrl + '/books/' + bookId + "/note", {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(formData),
            })

            const parsedResponse = await response.json() as ApiResponse<NoteResponse>;

            if (response.ok) {
                setFormData(initialFormData);
                setError('');
                toggleReload();
            } else if (response.status === 403) {
                sessionIssue();
            } else {
                if (parsedResponse.error) {
                    setError(parsedResponse.error);
                }

                if (parsedResponse.validationErrors) {
                    updateErrors(parsedResponse);
                }
            }
        } catch (error) {
            console.error('There was an error!', error);
        } finally {
            setSubmitting(false)
        }
    };


    let usernameField;
    if (userData) {
        usernameField = <Input name="username" value={userData?.username} disabled={true}/>;
    } else {
        usernameField = <Input name="username"
                               placeholder="JohnDoe42"
                               value={formData['displayName']}
                               onChange={(e) => {
            setFormData({...formData, displayName: e.target.value})
        }}/>
    }

    const handleDroppedOffChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            pickedUp: false,
            droppedOff: e.target.checked
        })
    }
    const handlePickedUpChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            droppedOff: false,
            pickedUp: e.target.checked
        })
    }

    return (

        <Sheet
            sx={{
                width: 650,
                my: 4,
                mx: 'auto',
                py: 3,
                px: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                borderRadius: 'sm',
                boxShadow: 'md',
            }}>

            <Typography level="h4" component="h1">Leave a note</Typography>
            <FormWithErrors onSubmit={handleSubmit} error={error} errors={errors} formData={formData} setFormData={setFormData}>
                <FormControl error={(!!errors["displayName"])} sx={{ display: !!userData ? 'none' : 'block'}}>
                    <FormLabel required={true}>Display Name</FormLabel>
                    {usernameField}
                    <FormHelperText>{errors["displayName"]}</FormHelperText>
                </FormControl>


                <Box display="flex" gap={3} sx={{mb:1}}>
                    <FormLabel>I just</FormLabel>
                    <Checkbox
                        label="picked the book up"
                        checked={formData['pickedUp']}
                        onChange={handlePickedUpChanged}/>

                    <Checkbox
                        label="dropped the book off"
                        checked={formData['droppedOff']}
                        onChange={handleDroppedOffChanged}
                    />
                </Box>

                <FormFieldWithErrors name="addressZip"
                                     type="text"
                                     label="at zip"
                                     required={false}
                                     placeholder="ZIP where you interacted with the book up"
                                     />

                <FormControl error={!!errors["note"]} >
                    <FormLabel>Comment</FormLabel>
                    <Textarea
                        name="note"
                        placeholder="I just wanted to say that..."
                        value={formData["note"] || ''}
                        minRows={3}
                        onChange={(e) => {
                            setFormData((prevFormData) => ({
                            ...prevFormData,
                            note: e.target.value
                            }));
                        }}
                    />
                    <FormHelperText>{errors["note"]}</FormHelperText>
                </FormControl>

                <Button sx={{mt: 1}} type="submit" value="Post" disabled={submitting}>
                    <CircularProgress sx={{display: submitting ? "inline-block" : "none"}}/>
                    Post!
                </Button>
            </FormWithErrors>
        </Sheet>
    )
}

export default NewNote