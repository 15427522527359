import React from 'react';
import SignUp from "./components/SignUp";
import Book from "./components/Book";
import Books from "./components/Books";
import HomePage from "./components/HomePage";
import NewBook from "./components/NewBook";
import {Route, Routes} from 'react-router-dom';
import LoginForm from "./components/LoginForm";
import Layout from "./components/Layout";
import VerifyEmail from "./components/VerifyEmail";
import Account from "./components/Account";
import BookPrintView from "./components/BookPrintView";
import PasswordResetRequest from "./components/PasswordResetRequest";
import PasswordReset from "./components/PasswordReset";
import NotFound from "./components/NotFound";
import {Sheet} from "@mui/joy";
import Unsubscribe from "./components/Unsubscribe";

function App() {
    return (
        <Routes>
            <Route path="/" element={<Layout/>}>
                <Route index element={<HomePage/>}/>
                <Route path="login" element={<LoginForm/>}/>
                <Route path="signup" element={<SignUp/>}/>
                <Route path="book">
                    <Route path=":bookId" element={<Book/>}/>
                    <Route path="new" element={<NewBook/>}/>
                    <Route path="print">
                        <Route path=":bookId" element={<BookPrintView/>}/>
                    </Route>
                </Route>
                <Route path="books" element={<Books/>}/>
                <Route path="verifyEmail">
                    <Route path=":verificationId" element={<VerifyEmail/>}/>
                </Route>
                <Route path="account" element={<Account/>}/>
                <Route path="requestPasswordReset" element={<PasswordResetRequest/>}/>
                <Route path="passwordReset/:resetId" element={<PasswordReset/>}/>
                <Route path="unsubscribe/:ucode" element={<Unsubscribe/>}/>
                <Route path="NotFound" element={<NotFound/>}/>
            </Route>
        </Routes>
    );
}

export default App;
