import React, {useState} from "react";
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import ApiResponse from "../types/ApiResponse";
import LoginResponse from "../types/LoginResponse";
import {Box, Button, CircularProgress, FormControl, Input, Link} from "@mui/joy";
import FormWithErrors from "./FormWithErrors";
import {useMessageContext} from "../hooks/MessageContext";
import {useUserDataContext} from "../hooks/UserDataContext";

const LoginForm = () => {
    const [error, setError] = useState('');
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState<Record<string, string>>({email: '', password: ''})
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const {setMessage} = useMessageContext();
    const {userData, loggedIn, logOut, setUserData} = useUserDataContext();
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL;
    const [submitting, setSubmitting] = useState(false);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        try {
            setSubmitting(true);
            const response = await fetch(apiUrl + '/auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const parsedResponse = await response.json() as ApiResponse<LoginResponse>;
            let userData = parsedResponse.data;

            if (response.ok) {
                setUserData({
                    username: userData.username,
                    token: userData.token,
                    email: userData.email,
                })
                setMessage("Welcome back, " + userData.username + "!", "success")
                navigate("/books");
            } else {
                if (parsedResponse.error) {
                    setError(parsedResponse.error);
                }

                if (parsedResponse.validationErrors) {
                    Object.entries(parsedResponse.validationErrors).forEach(([key, value]) => {
                        switch (key) {
                            case "email":
                                setEmailError(String(value));
                                break;
                            case "password":
                                setPasswordError(String(value));
                                break;
                        }
                    })
                }
            }
        } catch (error) {
            console.error('There was an error!', error);
        } finally {
            setSubmitting(false);
        }
    };

    const logout = () => {
        logOut();
        navigate("/");
        setMessage("You've been successfully logged out", "success");
    }

    if (loggedIn()) {
        return <Box sx={{
            width: "55%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
        }}>
            <Link href="#/account" underline="none" color="neutral" level="body-md">
                <Box sx={{mx: 2, alignItems: "center"}}>
                    {userData!.username}
                </Box>
            </Link>
            <Link onClick={logout}
                  component="button"
                  variant="outlined" color="neutral"
                  underline="none"
                  sx={{'--Link-gap': '0.5rem', pl: 1, py: 0.5, borderRadius: 'md', mx: 2, display: "block"}}
            >
                Logout</Link>
        </Box>;

    } else {
        return <Box sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            flexShrink: 1,
            gap: 1,
            mx: 'auto',
        }}>
            <FormWithErrors
                onSubmit={handleSubmit}
                error={error}
                errors={errors}
                formData={formData}
                setFormData={setFormData}
                formStylingProps={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                    gap: 2,
                }}>
                <FormControl>
                    <span className={"validationError"}>{emailError}</span>
                    <Input type="text" placeholder="your@email" onChange={(e) => {
                        setFormData({...formData, email: e.target.value})
                    }}/>
                </FormControl>

                <FormControl>
                    <span className={"validationError"}>{passwordError}</span>
                    <Input type="password" placeholder="password" onChange={(e) => {
                        setFormData({...formData, password: e.target.value})
                    }}/>
                </FormControl>

                <Button type="submit" value="Login" disabled={submitting}>
                    <CircularProgress sx={{display: submitting ? "inline-block" : "none"}}/>
                    Login
                </Button>

                <Link component={RouterLink} to="/signup" underline="none">or sign up</Link>
            </FormWithErrors>
            <Link component={RouterLink} to="/requestPasswordReset" underline="none">Recover Password</Link>
        </Box>
    }
}
export default LoginForm